<h4>{{ title }}</h4>
<div class="tabs">
    <div
        class="tab"
        *ngFor="let item of tabs; let i = index"
        (click)="tabClick.emit(i)"
        (click)="changeTab(i)"
        [ngClass]="{ active: selectedTab == i }"
    >
        {{ item }}
    </div>
</div>
<div>
    <ng-content></ng-content>
    <button tabindex="-1" type="button" aria-label="Close" class="close-btn" (click)="xClose()">
        <cue-icon [icon]="cueClose"></cue-icon>
    </button>
</div>
